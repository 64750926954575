export enum ChannelType {
  ID,
  USERNAME,
  HANDLE,
}

export interface ChannelMetadata {
  id: string
  country: string
  customUrl: string
  title: string
  description: string
  localized: {
    description: string
    title: string
  }
  publishedAt: string
  thumbnails: {
    default: {
      url: string
      width: number
      height: number
    }
    high: {
      url: string
      width: number
      height: number
    }
    medium: {
      url: string
      width: number
      height: number
    }
  }
}

function getCleanUrl(url) {
  return url.split("?")[0]
}

export interface YoutubeChannelUser {
  id: number
  name: string
  username: string
  photoUrl: string
  youtube: string
}

export function getYoutubeChannelTypeAndId(url) {
  const cleanUrl = getCleanUrl(url)

  if (cleanUrl.includes("/watch")) {
    return null
  }

  const handlePattern = /^https:\/\/(www\.)?youtube\.com\/(@[^\/]+|c\/[^\/]+|[^\/]+)$/
  const usernamePattern = /^https:\/\/(www\.)?youtube\.com\/user\/([^\/]+)$/
  const idPattern = /^https:\/\/(www\.)?youtube\.com\/channel\/?\/?(UC[a-zA-Z0-9_-]{22})$/

  let match
  if ((match = cleanUrl.match(handlePattern))) {
    if (match[2].includes("playlist")) {
      return null
    }
    // Remove 'c/' from the matched group if present
    const value = match[2].replace(/^c\//, "").replace(/^@/, "")
    return { type: ChannelType.HANDLE, value: value }
  }
  if ((match = cleanUrl.match(usernamePattern))) {
    // Corrected the group index to 2 for usernamePattern
    return { type: ChannelType.USERNAME, value: match[2] }
  }
  if ((match = cleanUrl.match(idPattern))) {
    return { type: ChannelType.ID, value: match[2] }
  }

  return null
}

export const getYoutubeChannelUser = async (
  youtubeChannelUrl: string
): Promise<YoutubeChannelUser> => {
  const result = getYoutubeChannelTypeAndId(youtubeChannelUrl)
  if (!result) {
    throw new Error("Invalid channel URL")
  }

  const { type, value } = result

  let urls: string[] = []
  switch (type) {
    case ChannelType.ID:
      urls.push(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${value}&key=${process.env.GOOGLE_API_KEY}`
      )
      break
    case ChannelType.USERNAME:
      urls.push(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet&forUsername=${value}&key=${process.env.GOOGLE_API_KEY}`
      )
    case ChannelType.HANDLE:
      urls.push(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet&forHandle=${value}&key=${process.env.GOOGLE_API_KEY}`
      )
      break
    default:
      throw new Error("Invalid channel URL")
  }

  let data
  while (urls.length) {
    try {
      const url = urls.shift()
      if (!url) {
        break
      }
      const response = await fetch(url)
      data = await response.json()
      if (data.pageInfo.totalResults === 0) {
        continue
      } else {
        break
      }
    } catch (error) {
      console.error(error)
      break
    }
  }

  if (data.pageInfo.totalResults === 0) {
    throw new Error("Invalid channel URL")
  }

  const metadata: ChannelMetadata = {
    id: data.items[0].id,
    ...data.items[0].snippet,
  }
  if (!metadata) {
    throw new Error("Invalid channel URL")
  }

  return {
    id: -1,
    name: metadata.title,
    username: metadata.customUrl.replace("@", "") || "",
    photoUrl: metadata.thumbnails.default.url,
    youtube: "https://www.youtube.com/channel/" + metadata.id,
  }
}
